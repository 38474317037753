<template>
  <svg
    fill="none"
    height="21"
    viewBox="0 0 16 21"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8.33301V14.333M8 14.333L5 11.333M8 14.333L11 11.333M13 19.333H3C1.89543 19.333 1 18.4376 1 17.333V3.33301C1 2.22844 1.89543 1.33301 3 1.33301H8.58579C8.851 1.33301 9.10536 1.43836 9.29289 1.6259L14.7071 7.04011C14.8946 7.22765 15 7.48201 15 7.74722V17.333C15 18.4376 14.1046 19.333 13 19.333Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>
